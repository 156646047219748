





























import {
  defineComponent,
  computed,
  ref,
  toRef,
} from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { SfButton } from '@storefront-ui/vue';
import { useBBXListings } from '@cellar-services/composables/useBBX';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import type { FullCellarItemInterface } from '@cellar-services/types';
import AlgoliaRefreshButton from '@vaimo-algolia/components/AlgoliaRefreshButton.vue';

export default defineComponent({
  name: 'RemoveListings',
  components: {
    AlgoliaRefreshButton,
    SfButton,
  },
  props: {
    selectedItems: {
      type: Array,
      required: true,
    },
  },
  emits: ['close:sidemodal'],
  setup (props, { emit }) {
    const selectedItems = toRef(props, 'selectedItems');
    const customerStore = useCustomerStore();
    const { sapDefaultID } = storeToRefs(customerStore);
    const { removeBBXListings } = useBBXListings();
    const isConfirmationButtonDisabled = ref<boolean>(false);

    const confirmationButtonText = computed<string>(() => (
      selectedItems.value.length > 1 ? 'Remove listings' : 'Remove listing'
    ));

    const confirmationText = computed<string>(() => {
      return selectedItems.value.length > 1 ?
        'Are you sure you want to remove the selected listings?' :
        'Are you sure you want to remove the selected listing?';
    });

    const removeSelectedListings = async (callback: Function) => {
      isConfirmationButtonDisabled.value = true;

      await removeBBXListings({
        account_id: sapDefaultID.value,
        listing_ids: selectedItems.value.map((item: FullCellarItemInterface) => item.listing_id),
      }, callback);

      isConfirmationButtonDisabled.value = false;

      emit('close:sidemodal');
    }

    return {
      confirmationButtonText,
      confirmationText,
      removeSelectedListings,
      isConfirmationButtonDisabled,
    };
  },
});
